<template>
	<div class="position-relative">
		<div class="d-flex justify-content-between mb-3">
			<div class="mb-2">
				<h2 class="playfairDisplay mb-0 text-primary" v-text="title"></h2>
			</div>
			<div class="mb-2">
				<button type="button" v-on:click="getPaises()" title="Actualizar" class="btn btn-outline-primary me-2" :disabled="loadingPaises">
					<font-awesome-icon :icon="faRedo" />
				</button>
				<router-link :to="{name: 'admin.paises.create'}" class="btn btn-primary">
					<font-awesome-icon :icon="faPlus" /> Agregar país
				</router-link>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div v-if="(loadingPaises && !paginate) || paisesFiltered.length<=0">
					<div v-if="(loadingPaises && !paginate)" class="text-muted text-center py-5">
						<div class="spinner-grow" role="status" style="width: 3rem; height: 3rem;">
							<span class="visually-hidden">Loading...</span>
						</div>
						<h2>
							Cargando...
						</h2>
					</div>
					<div v-else class="py-3">
						<div class="text-center mx-auto bg-light rounded-circle p-5" style="width: 200px; height: 200px;">
							<img :src="SVGEmpty" class="img-fluid">
						</div>
						<div class="text-center">
							<p>
								No se encontraron registros
							</p>
							<router-link :to="{name: 'admin.paises.create'}" class="btn btn-primary">
								<font-awesome-icon :icon="faPlus" /> Agregar país
							</router-link>
						</div>
					</div>
				</div>
				<div class="table-responsive" v-else>
					<table class="table table-hover">
						<thead>
							<th width="60">Código</th>
							<th>Nombre</th>
							<th>Moneda</th>
							<th>Prefijo</th>
							<th width="150" class="d-none d-lg-table-cell d-xl-table-cell">Fecha de actualización</th>
							<th width="120"></th>
						</thead>
						<tbody>
							<tr v-for="(pais, index) in paisesFiltered" :key="index" class="cursor-pointer">
								<th>{{pais.CODIGO_PAIS}}</th>
								<td>{{pais.NOMBRE_PAIS}}</td>
								<td align="center">{{pais.MONEDA}}</td>
								<td align="center">
									<span class="badge bg-primary">
										{{pais.PREFIJO}}
									</span>
								</td>
								<td class="text-center d-none d-lg-table-cell d-xl-table-cell">
									<span class="badge bg-dark">
										{{$filters.dateTime(pais.FECHA_ACTUALIZACION)}}
									</span>
								</td>
								<td align="right">
									<div class="table-options">
										<router-link :to="{name: 'admin.paises.edit', params: {id: pais.CODIGO_PAIS}}" class="btn btn-link me-1">
											<font-awesome-icon :icon="faEdit" />
										</router-link>

										<DeleteButton class="btn btn-link" :codigo="pais.CODIGO_PAIS" url="deletePaises">
											<font-awesome-icon :icon="faTrash" />
										</DeleteButton>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<router-view />
		<infinite-scroll
			@infinite-scroll="infiniteHandler"
			:noResult="noResult"
			>
		</infinite-scroll>
		<div v-if="noResult && paisesFiltered.length>0 && !loadingPaises" class="text-center text-muted">
			No hay más resultado
		</div>
	</div>
</template>
<script type="text/javascript">
import InfiniteScroll from 'infinite-loading-vue3'
import SVGEmpty from '@/assets/img/icons/no_data.svg'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DeleteButton from '@/components/DeleteButton.vue'
import { faPlus, faFilter, faEdit, faTrash, faRedo } from '@fortawesome/free-solid-svg-icons'
export default {
	components: {
		FontAwesomeIcon,
		InfiniteScroll,
		DeleteButton
	},
	data(){
		return {
			faPlus,
			faFilter,
			faEdit,
			faTrash,
			faRedo,
			SVGEmpty,
			loadingPaises: false,
			noResult: false,
			paginate: false
		}
	},
	mounted(){
		this.getPaises()
	},
	methods: {
		infiniteHandler(){
			if (!this.noResult && !this.loadingPaises) {
				this.paginate = true
				this.getPaises()
			}
		},
		getPaises(){
			this.loadingPaises = true
			var filters = {}
			if (this.paginate) {
				filters.url = this.paises.next_page_url
			}
			this.$store.dispatch('getPaises', filters).then((res) => {
				if(res.data.data.length > 0 && this.paises.next_page_url != null){
					this.noResult = false
				}else{
					this.noResult = true
				}
				return res
			}).catch(err => {
				return err
			}).then(() => {
				this.loadingPaises = false
				this.paginate = false
			})
		},
	},
	computed: {
		paises() {
			return this.$store.getters.paises || {}
		},
		paisesFiltered() {
			return this.paises.data || []
		},
		title(){
			return this.$route.meta.title ? this.$route.meta.title : ''
		}
	}
}
</script>